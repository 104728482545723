<template>
  <div class="Policyrules">
    <Header back="返回" title="产品列表" index="首页" titleOne="政策管理" titleTwo="产品列表" beforeTitle="政策规则" />
    <div class="content">
      <h1>基本信息</h1>
      <ul>
        <li>
          <span>产品名称</span>
          <p>{{ this.productform.title }}</p>
        </li>
        <li>
          <span>产品类型</span>
          <p>{{ this.productform.type }}</p>
        </li>
        <li>
          <span>主管部门</span>
          <p>{{ this.productform.department_name }}</p>
        </li>
        <li>
          <span>所属区域</span>
          <p>{{ this.productform.area }}</p>
        </li>
        <li>
          <span>依据来源</span>
          <p>{{ this.productform.url }}</p>
        </li>
        <li>
          <span>产品描述</span>
          <p>{{ this.productform.description }}</p>
        </li>
      </ul>
      <h1>规则条件</h1>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column width="50" :render-header="renderHeader">
          <template slot-scope="scope">
            <button v-if="tableData.length > 0" @click="handleDelete(scope.row.id)" class="del-btn">
              <i class="delLine"></i>
            </button>
          </template>
        </el-table-column>
        <el-table-column label="条件名称">
          <template slot-scope="scope">
            <el-select v-model="scope.row.value" placeholder="请选择类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="计算类型">
          <template slot-scope="scope">
            <el-select v-model="scope.row.value" placeholder="请选择类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="数值/明细">
          <template slot-scope="scope">
            <el-input v-model="scope.row.detail"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      productform: {
        title: "",
        type: "",
        keywords: "",
        url: "",
        province_id: "",
        city_id: "",
        town_id: "",
        source_url: "",
        description: "",
        conditions: "",
        award: "",
      },
      tableData: [],
      options: [],
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/policy/product_detail", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.productform = res.data;
          this.productform.member_id = this.meber_id;
          this.productform.type = res.data.type.toString();
        });
    }
  },
  methods: {
    renderHeader (h) {
      return (
        <button class="addBtn" type="default" onClick={() => this.addLine()}>
          {" "}
          <i class="addLine"> </i>
        </button>
      );
    },
    onSubmit () { },
    addLine () {
      //添加行数
      var newValue = {};
      this.tableData.push(newValue);
    },
    handleDelete (index) {
      //删除行数
      this.tableData.splice(index, 1);
    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.tableData));
    },
  },
};
</script>

<style>
.Policyrules ul li {
  margin-top: 20px;
}

.Policyrules ul li span {
  color: #cbcfe3;
  margin-right: 20px;
  float: left;
}

.Policyrules ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}
</style>
